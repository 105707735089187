/*
  環境情報
    開発環境:dev
    総合テスト環境:sta
    本番環境:prod
*/
export const ENVIRONMENTS = {
  DEV: 'dev',
  STA: 'sta',
  PROD: 'prod',
};

/**
 * ヘッダーの高さ（px）
 */
export const HEADER_HEIGHT = 56;

/**
 * ソートカスタマイズテキスト
 */
export const SORTTEXT = '並べ替え';

/**
 * ユーザー種類のリスト
 */
export const USER_KIND_LIST = [
  { comboItem: '組合員', code: 0 },
  { comboItem: '管理者', code: 1 },
  { comboItem: '組合', code: 2 },
  { comboItem: '組合営業担当者', code: 3 },
  { comboItem: '郵送委託先', code: 4 },
];

export const USER_TYPES = {
  UNIONIST: 0,
  ADMIN: 1,
  UNION: 2,
  SALES: 3,
  MAIL_SUBCONTRACTOR: 4,
};

/**
 * カードタイプ
 */
export const CARD_TYPES = ['ETCコーポレートカード', '法人ETCカード', '燃料カード'];
export const CARD_TYPES_LIST = [
  { comboItem: 'ETCコーポレートカード', code: 0 },
  { comboItem: '法人ETCカード', code: 1 },
  // { comboItem: '燃料カード', code: 2 },
];

/**
 * Amplify Cacheでローカルストレージに保存された情報の保持日数
 */
export const AUTH_EXPIRATION_DAYS = 7;

/**
 * パスワードの長さ
 */
export const PASSWORD_LENGTH = 8;

/**
 * モーダルの種類
 */
export const DIALOG_TYPE = {
  ALERT: 0,
  CONFIRM: 1,
};

/**
 * モーダルの種類
 */
export const DIALOG_TITLE = {
  CONFIRM: '確認',
  INFO: '情報',
  SUCCESS: '成功',
  WARNING: '警告',
  ERROR: 'エラー',
};

/**
 * 自動採番連番データ種類
 */
export const SEQUENCE_TYPE = {
  USER_NO: 0,
  NEWS_ID: 1,
  COMMISION_ID: 2,
};

/**
 * 郵送通知
 */
export const SEND_MAIL = {
  NOT_REQUIRED: 0,
  REQUIRED: 1,
};

/**
 * メール通知
 */
export const SEND_EMAIL = {
  NOT_REQUIRED: 0,
  REQUIRED: 1,
};

/**
 * ダウンロード状態
 */
export const DOWNLOAD_STATUS = {
  DOWNLOADED: 0,
  NOT_DOWNLOADED: 1,
};

/**
 * 登録・編集ページタイトル
 */
export const EDIT_TYPE = {
  CREATE: '登録',
  EDIT: '編集',
};

/**
 * 削除済フラグ
 */
export const IS_DELETED_FLG = {
  VALID: 0,
  DELETED: 1,
};

/**
 * 標準データ取得上限
 */
export const DATA_COUNT_LIMIT = 1000;
/**
 * 一つテーブルデータ件数上限
 */
export const MAX_LIMIT = 999999999;

/**
 * メールのタイトル
 */
export const EMAIL_SUBJECT = {
  USER_CREATED: '【総合物流協同組合】総合物流WEBページユーザー登録完了のお知らせ', //複製時修正
  USER_EMAIL_CHANGE: '【総合物流協同組合】ユーザーメールアドレス更新のお知らせ', //複製時修正
};
/**
 * アップロード、許可されるのファイルタイプ
 * ZIP、PDF、Excelファイル（.xls .xlsx）、CSV、TXT
 */
export const ALLOW_FILE_TYPE = [
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/zip',
  'application/x-zip-compressed',
  'text/csv',
  'text/plain',
];
/**
 * アップロード、許可されないのファイル符号
 */
export const NOT_ALLOW_FILE_SYMBOL = /[@%'+¥\\/:*?"<>|]/im;

/**
 * TOP画面：画像URLの有効期間（秒）
 */
export const TOP_IMAGE_EXPIRES = 86400;

/**
 * TOP画面：最新のお知らせ件数
 */
export const TOP_NEWS_LIST_LIMIT = 10;

/**
 * コンテンツ管理：スライドイメージ格納用S3フォルダ
 */
export const CONTENTS_S3_FOLDER_IMAGE = 'contents/slides/images';

/**
 * コンテンツ管理：スライド登録最大件数
 */
export const CONTENTS_SLIDES_MAX = 99;

/**
 * コンテンツ管理：ソートキーに設定する値
 */
export const CAROUSEL_SEQUENCE_SORT_FLAG = 0;
export const NEWS_DATE_TIME_SORT_FLAG = 0;
export const UNIONISTS_NEWS_DATE_TIME_NEWSID_UNIONISTNO_SORT_FLAG = 0;

/**
 * コンテンツ管理：お知らせ（個別）通知メール送信可否
 */
export const NOTIFICATION_EMAIL_SENDABLE = {
  DISABLED: 0, // 通知しない
  ENABLED: 1, // 通知する
};

/**
 * コンテンツ管理：お知らせ（個別）通知メール送信済みフラグ
 */
export const SENT_NOTIFICATION_EMAIL_FLAG = {
  UNSENT: 0, // 未送信
  SENT: 1, // 送信済み
};

/**
 * コンテンツ管理：メールテンプレートタイプ
 */
export const EMAIL_TEMPLATES_TYPE = {
  STATEMENT_ISSUE_UNIONISTS: 0, // 組合員向けメール通知(請求書/利用明細発行通知)
  STATEMENT_DOWNLOAD: 1, // メール通知(請求書/利用明細DL未済通知)
  SALES_COMMISSION: 2, // 営業コミッション通知
  // 新しいメールテンプレート追加が必要であれば、3番を先に使います、メールテンプレート内容の改造が必要
  // STATEMENT_ISSUE_SALES: 3, // 営業担当社向けメール通知(請求書/利用明細発行通知)
  UNIONIST_NEWS: 4, // お知らせ（個別）メール通知
};

/**
 * 郵送委託先ダウンロード：ダウンロードワーク表示件数
 */
export const DOWNLOADWORK_SHOW_COUNT = 3;

/**
 * 郵送委託先ダウンロード：ダウンロード準備完了ステータス
 */
export const DOWNLOAD_OK_STATUS = 200;

/**
 * マニュアルの種別
 */
export const MANUAL_KINDS = {
  ADMIN_UNION_MANUAL: 'manual/adminUnionManual.pdf',
  UNIONIST_MANUAL: 'manual/unionistManual.pdf',
  SALES_MANUAL: 'manual/salesManual.pdf',
  MAIL_SUBCONTRACTOR_MANUAL: 'manual/mailSubcontractorManual.pdf',
};

/**
 * Googleアナリティクスのイベント取得ID
 */

export const ANALYTICS_ID = 'G-6DPBX4X6N8';
