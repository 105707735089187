import store from '@/store';
import { API } from 'aws-amplify';
import { createOperationLogs } from '@/graphql/mutations';
import { v4 as uuidv4 } from 'uuid';
import { ENVIRONMENTS } from '@/assets/js/const';
import { messageKeys, getLogMessage } from './logMessages';
import { getDateYYYYMMDD, getTimeHHmmss } from '@/assets/js/day';

/**
 * 操作ログの登録
 * @param {*} messageKey
 * @param {*} component
 * @param {*} func
 * @param {*} param
 * @param {*} e
 * @returns
 */
export const addLog = async ({ messageKey, component, func, param, errorObject = null }) => {
  // console.log({ messageKey });
  // console.log({ component });
  // console.log({ func });
  // console.log({ param });
  // console.log({ errorObject });
  let logLevel, logMessage;

  // ログレベル取得
  try {
    logLevel = getLogLevel(messageKey);
    logMessage = getLogMessage(messageKey);
  } catch (error) {
    // ログレベルが取れないエラーメッセージをログレベルと合わせてセット
    logLevel = LOG_LEVELS.E;
    logMessage = getLogMessage(messageKeys.E3000);
    Object.assign(param, { message: 'ログレベルが取得できませんでした' });
  }

  let input;

  try {
    // 開発環境以外ではDEBUG・INFOログを出力しない
    switch (process.env.VUE_APP_ENV) {
      case ENVIRONMENTS.DEV:
        break;
      case ENVIRONMENTS.STA:
      case ENVIRONMENTS.PROD:
        // if (logLevel == LOG_LEVELS.D || logLevel == LOG_LEVELS.I) return;
        if (logLevel == LOG_LEVELS.D) return;
        break;
      default:
        break;
    }

    // // Cookieに保存しているバージョン取得
    // try {
    //   const versionCache = Cache.getItem('version');
    //   Object.assign( param, { version: versionCache } );
    // } catch (error) {
    //   // 何もしない
    // }

    input = {
      LogID: uuidv4(),
      LogLevel: logLevel,
      UserNo: getUserNo(),
      Message: logMessage,
      Component: component ?? '',
      Function: func ?? '',
      Param: JSON.stringify(param),
      UserAgent: window.navigator.userAgent.toLowerCase(),
      Date: getDateYYYYMMDD(),
      Time: getTimeHHmmss(),
    };

    if (errorObject) input.Error = errorObject;
  } catch (error) {
    // TODO:substringでエラーが発生した場合の対応
  }

  try {
    // IAM認証モードでAPIを実行
    const result = await API.graphql({
      query: createOperationLogs,
      variables: { input: input },
      authMode: 'AWS_IAM',
    });
    console.log({ result });
  } catch (error) {
    // 何もしない。
    console.error('ログ出力時にエラー', error);
  }
};

/**
 * ログレベルの定数
 * ログIDの先頭1文字で判定してDB書き込みする文字列に使用する。
 * 該当しない文字列が指定された場合は呼出し元のtry/catchにてエラーハンドリングする。
 */
const LOG_LEVELS = {
  D: 'DEBUG',
  I: 'INFO',
  W: 'WARN',
  E: 'ERROR',
  F: 'FATAL',
};

const getLogLevel = (messageKey) => {
  return LOG_LEVELS[messageKey.substring(0, 1)];
};

/**
 * UserNoを返却（未ログインの場合-1を返却）
 * @returns UserNo
 */
const getUserNo = () => {
  const user = store.getters?.$_user;
  if (user) return user.UserNo;
  return -1;
};
